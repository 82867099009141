import styles from "./PageContainer.module.scss";
import { useAuthStore } from "src/Stores/AuthStore";
import useFeatureFlagsStore from "src/Stores/FeatureFlagsStore.tsx";

export default function PageContainer({
  children,
  containerProps: {
    className,
    children: _containerPropsChildren,
    ...containerProps
  } = {},
}: {
  children: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const auth = useAuthStore();
  const isLogged = auth.isAuthenticated();
  const { features } = useFeatureFlagsStore();
  const hasNotifications = features?.NOTIFICATIONS;
  const showNotifications = isLogged && hasNotifications;

  return (
    <div
      className={[
        styles.container,
        showNotifications ? styles.hasNotifications : "",
        className,
      ].join(" ")}
      {...containerProps}
    >
      {children}
      {_containerPropsChildren}
    </div>
  );
}
