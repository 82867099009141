import Modal from "@components/Modal/Modal";
import styles from "./ModalRebookTaxi.module.scss";
import { ApiAction } from "src/models/ApiAction";
import { useTranslation } from "react-i18next";
import Select from "@components/Select/Select";
import { useState } from "react";
import Button from "@components/Button/Button";
import { Oval } from "react-loader-spinner";
import { format } from "date-fns";
import { useCurrentMission } from "src/Stores/CurrentMission";

function getStartAndEndLimit({
  rescheduleTaxiLimitStart,
  rescheduleTaxiLimitEnd,
  currentScheduledAtDate,
}: {
  rescheduleTaxiLimitStart?: string;
  rescheduleTaxiLimitEnd?: string;
  currentScheduledAtDate: Date;
}) {
  const isSameDay = (date1: Date, date2: Date) =>
    date1.getDate() === date2.getDate();

  const getLimit = (
    limit: string | undefined,
    defaultDate: Date,
    hours: number,
    minutes: number
  ) => {
    if (limit) {
      const limitDate = new Date(limit);
      if (isSameDay(limitDate, defaultDate)) {
        return limitDate;
      }
    }
    const newDate = new Date(defaultDate);
    newDate.setHours(hours, minutes, 0, 0);
    return newDate;
  };

  const startLimit = getLimit(
    rescheduleTaxiLimitStart,
    currentScheduledAtDate,
    0,
    0
  );
  const endLimit = getLimit(
    rescheduleTaxiLimitEnd,
    currentScheduledAtDate,
    23,
    59
  );

  return {
    startLimit,
    endLimit,
  };
}

function HourMinuteSelector({
  minHourMinute,
  maxHourMinute,
  onChange,
  selectedHour,
  selectedMinute,
}: {
  minHourMinute: [number, number];
  maxHourMinute: [number, number];
  onChange: (hour: number, minute: number) => void;
  selectedHour: number;
  selectedMinute: number;
}) {
  const hours = Array.from(
    { length: maxHourMinute[0] - minHourMinute[0] + 1 },
    (_, i) => i + minHourMinute[0]
  );
  const maxMinute = selectedHour === maxHourMinute[0] ? maxHourMinute[1] : 59;
  const minMinute = selectedHour === minHourMinute[0] ? minHourMinute[1] : 0;
  const minutes = Array.from({ length: 1 + maxMinute - minMinute }).map(
    (_, i) => i + minMinute
  );

  return (
    <div className="flex flex-row justify-between align-center">
      <Select
        containerProps={{
          className: "w-1/2",
        }}
        placeholder="Select hour"
        optionsV2={hours.map((hour) => ({
          value: hour.toString(),
          label: hour < 10 ? `0${hour}` : hour.toString(),
        }))}
        onChangeIndex={(index) => {
          if (index === null) {
            return;
          }
          const newHour = parseInt(hours[index].toString());
          onChange(
            newHour,
            newHour === minHourMinute[0]
              ? Math.max(minHourMinute[1], selectedMinute)
              : newHour === maxHourMinute[0]
              ? Math.min(maxHourMinute[1], selectedMinute)
              : selectedMinute
          );
        }}
        selectedIndex={hours.findIndex((hour) => hour === selectedHour)}
      />
      <Select
        containerProps={{
          className: "w-1/2",
        }}
        placeholder="Select minute"
        optionsV2={minutes.map((minute) => ({
          value: minute.toString(),
          label: minute < 10 ? `0${minute}` : minute.toString(),
        }))}
        onChangeIndex={(index) => {
          if (index === null) {
            return;
          }
          onChange(selectedHour, parseInt(minutes[index].toString()));
        }}
        selectedIndex={minutes.findIndex((minute) => minute === selectedMinute)}
      />
    </div>
  );
}

export default function ModalRebookTaxi({
  onRequestClose,
  onConfirm,
  rescheduleTaxiLimits,
  currentScheduledAt,
  rescheduleTaxiAction,
}: {
  onRequestClose: () => void;
  rescheduleTaxiLimits?: {
    start?: string;
    end?: string;
  };
  rescheduleTaxiAction: ApiAction;
  currentScheduledAt: string;
  onConfirm: () => void | Promise<void>;
}) {
  const { callAction } = useCurrentMission();
  const { t } = useTranslation();
  const [rescheduleAt, setRescheduleAt] = useState<Date>(
    new Date(currentScheduledAt)
  );
  const [confirmLoading, setConfirmLoading] = useState(false);

  const layout = "default";

  const currentScheduledAtDate = new Date(currentScheduledAt);

  const { startLimit, endLimit } = getStartAndEndLimit({
    rescheduleTaxiLimitStart: rescheduleTaxiLimits?.start,
    rescheduleTaxiLimitEnd: rescheduleTaxiLimits?.end,
    currentScheduledAtDate,
  });

  // console.log("EDIT_TAXI startLimit ok", startLimit);
  // console.log("EDIT_TAXI endLimit", endLimit);

  // console.log("EDIT_TAXI rescheduleAt", rescheduleAt);

  return (
    <Modal
      title={t("searchNewTaxi")}
      disableModalScroll
      containerProps={{
        style: {
          maxWidth: 500,
          minWidth: 500,
        },
        className: `${styles.modalConfirmContainer} ${
          layout ? styles[layout] : ""
        }`,
      }}
      contentContainerProps={{
        className: styles.modalContentContainer,
      }}
      onRequestClose={onRequestClose}
      layout={layout}
      style={{
        content: {
          overflow: "visible",
        },
      }}
    >
      <div className={styles.formContainer}>

        <div
          className={`flex flex-row justify-between align-center ${styles.message}`}
        >
          {t("taxi_schedule", {
            date: currentScheduledAtDate.toLocaleDateString(),
            time: format(currentScheduledAtDate, "HH:mm"),
          })}
        </div>

        <div
          className={`flex flex-row justify-between align-center ${styles.message}`}
        >
          {t("searchNewTaxiContent")}
        </div>

        <div>
          <div className={styles.label}>{t("leave_at")}</div>
          <div className={`flex flex-row justify-between align-center`}>
            <HourMinuteSelector
              minHourMinute={[startLimit.getHours(), startLimit.getMinutes()]}
              maxHourMinute={[endLimit.getHours(), endLimit.getMinutes()]}
              selectedHour={rescheduleAt.getHours()}
              selectedMinute={rescheduleAt.getMinutes()}
              onChange={(hour, minute) => {
                const newDate = new Date(currentScheduledAtDate);
                newDate.setHours(hour, minute, 0);
                setRescheduleAt(newDate);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonActions}>
        <Button
          onClick={async () => {
            try {
              setConfirmLoading(true);

              await callAction(rescheduleTaxiAction, {
                newDepartureTime: format(rescheduleAt, "yyyy-MM-dd'T'HH:mm:ss"),
              });

              const responseConfirm = onConfirm();
              if (responseConfirm instanceof Promise) {
                await responseConfirm;
              }

              onRequestClose();
            } catch (e) {
              console.error(e);
              setConfirmLoading(false);
            }
          }}
          containerProps={{
            style: {
              height: 50,
            },
            disabled: confirmLoading,
          }}
        >
          {confirmLoading ? (
            <Oval
              height={20}
              width={20}
              color="white"
              secondaryColor="white"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            t("button.confirm")
          )}
        </Button>

        <Button
          label={t("button.cancel")}
          onClick={() => {
            onRequestClose();
          }}
          type="secondary"
        />
      </div>
    </Modal>
  );
}
