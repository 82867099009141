import styles from './Itinerary.module.scss';
import { Arrow, Leaf } from '@assets/icons/icons';
import { Fragment } from 'react';
import ItineraryStep from './ItineraryStep';
import SelectableContent from '@components/SelectableContent/SelectableContent';
import { ItineraryComponentProps } from './types';
import {formatDistance, getTimeFormatted, getTransportationIcon} from './helpers';
import formatDuration from 'src/helpers/duration';
import ItineraryTag from './ItineraryTag';
import { TagKey } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import useItinerary from './useItinerary';
import { useTranslation } from 'react-i18next';

export default function Itinerary(itineraryProps: ItineraryComponentProps) {

  const { t } = useTranslation();

  const {
    testid,
    selected = false,
    durationInMinutes,

    transportations = [],
    detailedTags,
    co2Emission,

    sections = [],

    onSelect = () => void 0,
    onSelectParams,
    containerProps,
    remainingCosts,

    readOnly = false,
    showRemainingCosts = true,
  } = itineraryProps;

  const {
    isTaxiSelected,
    selectedAllSectionsIndex,

    arrivalDateTime,
    departureDateTime,
    formattedPrice,
    kgCO2e,
    onClickTaxiIndex,

  } = useItinerary(itineraryProps);

  function getDateTimeFormatted(date: Date) {
    // day with 2 digits
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    // hours with 2 digits
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${t('at')} ${hours}:${minutes}`;
  }


  return (
    <SelectableContent

      selected={selected}
      onSelect={() => {
        onSelect({
          selected: !selected,
          selectedAllSectionsIndex,
          onSelectParams,
        });
      }}

      readOnly={readOnly}

      containerProps={containerProps}
      header={detailedTags && (<div className={styles.infosHead}>

            <div className={styles.tags}>
              {(Object.keys(detailedTags) as TagKey[]).map((tagKey, i) => detailedTags[tagKey] ? (
                  <ItineraryTag
                      key={i}
                      tagKey={tagKey}
                      label={detailedTags[tagKey]?.label}
                      hoverLabel={detailedTags[tagKey]?.hover}
                      containerProps={{ className: `${styles.tag} mr-2` }}
                  />
              ): null)}
            </div>

      </div>)}
      content={(
        <div className={styles.infosContainer} data-testid={testid}>

          <div className={styles.infosContent}>

            <div className={styles.itineraryIconsTransportation}>
              {transportations.map((transportation, i) => (
                <Fragment key={i}>
                  {i !== 0 && (
                    <div className={styles.stepSeparator}>
                      <Arrow width={12} height={12} />
                    </div>
                  )}
                  {transportation === 'taxi' && (
                    <div className={`${styles.step} ${styles.stepTaxi} ${isTaxiSelected(i) ? styles.stepTaxiSelected : ""}`} onClick={(e) => onClickTaxiIndex(i, e)}>
                      {getTransportationIcon(transportation)}
                      <div className={styles.label}>Taxi</div>
                    </div>
                  )}
                  {transportation !== 'taxi' && (
                  <div className={styles.step}>
                    {(function () {
                      const icon = getTransportationIcon(transportation);
                      let label: string | null = null;
                      const durationInMinutes: number = sections[i]?.options[0]?.durationInMinutes || 0;
                      switch (transportation) {
                        case "cab":
                          label = "Taxi";
                          break;
                        case "car":
                        case "vrental":
                          label = t('rental');
                          break;
                        case "train":
                          label = "Train";
                          break;
                        case "walk":
                          label = durationInMinutes ? (
                            formatDuration({
                              value: durationInMinutes,
                              unit: 'minutes'
                            })+"\n"+formatDistance(sections[i].distanceInMeters)
                          ) : "Marche";
                          break;
                        case "arrival":
                          label = formatDistance(sections[i].distanceInMeters);
                          break;
                        default:
                          return null;
                      }
                      return (
                        <Fragment>
                          {icon}
                          <div className={styles.label}>{label}</div>
                        </Fragment>
                      );
                    })()}
                  </div>
                  )}
                </Fragment>
              ))}
            </div>

            <div className={styles.itineraryDetails}>
              <div className={styles.pickupDate}>{t('departure_at')} {getDateTimeFormatted(departureDateTime)}</div>
              <div className={styles.duration}>
                <span className={styles.durationLabel}>{t('duration')}: </span>
                <span className={styles.durationValue}>{formatDuration({
                  unit: "minutes",
                  value: durationInMinutes,
                })}</span>
              </div>
              <div className={styles.arrivalAt}>
                <span className={styles.arrivalAtLabel}>{t('arrival_at')}: </span>
                <span className={styles.arrivalAtValue}>{getTimeFormatted(arrivalDateTime)}</span>
              </div>
            </div>

            <div className={styles.itineraryPrice}>
              <div className={styles.priceValue}>{formattedPrice}</div>
              {remainingCosts && (
                <div className={styles.priceWarning} data-testid="warning-remaining-costs">{remainingCosts.formatted + " " + remainingCosts.currency.symbol} à charge</div>
              )}
              {/* <div className={styles.priceInfo}>100% réservable</div> */}
            </div>

            {co2Emission && (
              <div className={styles.co2Infos}>
                <div className={[styles.co2Icons, co2Emission.color === "warning" ? styles.co2IconsWarning : ""].join(" ")}>
                  {co2Emission.level >= 1 && <Leaf width={12} height={12} />}
                  {co2Emission.level >= 2 && <Leaf width={12} height={12} />}
                  {co2Emission.level >= 3 && <Leaf width={12} height={12} />}
                </div>
                <div className={styles.co2Value}>{kgCO2e}kgCO2e</div>
              </div>
            )}

          </div>

        </div>
      )}


      expandedContent={sections ? (
        <div className={styles.stepsContainer}>
          {sections.map((step, i) => (
            <ItineraryStep
              key={i}
              containerProps={{
                className: [
                  styles.stepContainer,
                  i === 0 ? styles.stepContainerFirst : "",
                  i === sections.length - 1 ? styles.stepContainerLast : "",
                  step.type === 'taxi' && !isTaxiSelected(i) ? styles.stepContainerTaxiNotSelected : "",
                ].join(" "),
              }}
              {...step}
              destination={step.destination}
              origin={step.origin}
              distanceInMeters={step.distanceInMeters}
              showRemainingCosts={showRemainingCosts}
            />
          ))}
        </div>
      ) : null}
    />

  )
}