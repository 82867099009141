import ModalAlert from "@components/ModalConfirm/ModalAlert";
import ModalConfirm from "@components/ModalConfirm/ModalConfirm";
import ModalPreviewMobile from "@components/ModalPreviewMobile/ModalPreviewMobile";
import { Outlet } from "react-router-dom";
import { useConfirmAlert } from "src/Stores/ConfirmAlertStore";
import { useMobilePreview } from "src/Stores/MobilePreviewStore";

import {getAppEnv} from "../../appEnv.ts";
import { useCallback, useEffect, useRef } from "react";
import useFeatureFlagsStore from "src/Stores/FeatureFlagsStore.tsx";

const version = window.env.version as any;

export default function AppContainer() {

  const { modalProps } = useConfirmAlert();
  const {
    modalProps: modalPropsMobilePreview,
    close: closeMobilePreview
  } = useMobilePreview();
  const { confirm } = useConfirmAlert();
  const { fetchFeatureFlags } = useFeatureFlagsStore();
  const versionHasChanged = useRef(false);

  const checkVersion = useCallback(async () => {
    if (versionHasChanged.current) {
      return;
    }
    const response = await fetch('/version.json');
    const newVersion = await response.json();
    if (newVersion.consoleAgent !== version?.consoleAgent) {
      versionHasChanged.current = true;
      if (confirm({
        title: "Nouvelle version",
        message: "Une nouvelle version de l'application est disponible. Voulez-vous recharger la page ?",
        cancelLabel: "Non",
        confirmLabel: "Oui",
        onConfirm: () => {
          window.location.reload();
        },
        onCancel: () => {
          console.log("cancel");
        }
      })) {
        console.log("reload");

      }
    }
  }, [confirm]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkVersion();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [checkVersion]);


  useEffect(() => {
    fetchFeatureFlags(); 
  }, [fetchFeatureFlags]);

  return (
    <>
      <div style={{
        position: "fixed",
        bottom: "0",
        left: "0",
        fontSize: "30px",
        zIndex: 9999999
      }}>{version.consoleAgent} - { getAppEnv() }</div>

      {modalPropsMobilePreview && (
        <ModalPreviewMobile
          onRequestClose={closeMobilePreview}
          modalProps={modalPropsMobilePreview}
        />
      )}

      {modalProps && modalProps.type === "confirm" && (
        <ModalConfirm
          confirmText={modalProps.confirmText || "Oui"}
          cancelText={modalProps.cancelText || "Non"}
          {...modalProps}
        />
      )}

      {modalProps && modalProps.type === "alert" && (
        <ModalAlert
          {...modalProps}
        />
      )}
      <Outlet />
    </>
  )
}