import { create } from 'zustand'
import { devtools } from 'zustand/middleware'


export type ConfirmProps = {
  title: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => void;
  onCancel: () => void;
  message_type?: 'default' | 'success' | 'error';
  layout?: 'default' | 'minimal';
};

export const useConfirmAlert = create<{
  close: () => void;
  modalProps: any;
  setModalProps: (modalProps: any) => void;
  alert: ({
    title,
    message,
    message_type,
    layout,
    confirmLabel,
    confirmType,
    onClose,
  }: {
    title: string;
    message: string | React.ReactNode;
    layout?: 'default' | 'minimal';
    confirmLabel?: string;
    confirmType?: 'primary' | 'secondary';
    message_type?: 'default' | 'success' | 'error';
    onClose?: () => void;
  }) => void;
  confirm: (args: ConfirmProps) => void | Promise<void>;
}>()(
  devtools(
    (set) => ({
      modalProps: null,
      setModalProps: (modalProps: any) => set({ modalProps }),

      close: () => {
        set({
          modalProps: null,
        })
      },

      alert: ({
        title,
        confirmLabel,
        confirmType = 'primary',
        message,
        message_type,
        layout = 'default',
        onClose,
      }: {
        title: string;
        confirmLabel?: string;
        confirmType?: 'primary' | 'secondary';
        message: string | React.ReactNode;
        message_type?: 'default' | 'success' | 'error';
        layout?: 'default' | 'minimal';
        onClose?: () => void;
      }) => {
        set({
          modalProps: {
            title,
            confirmLabel,
            confirmType: confirmType,
            message,
            message_type,
            layout,
            type: "alert",
            onClose,
          }
        })
      },

      confirm: ({
        title,
        message,
        confirmLabel,
        cancelLabel,
        onConfirm,
        onCancel,
        message_type,
        layout = 'default',
      }: {
        title: string;
        message: string;
        confirmLabel?: string;
        cancelLabel?: string;
        onConfirm: () => void;
        onCancel: () => void;
        message_type?: 'default' | 'success' | 'error';
        layout?: 'default' | 'minimal'
      }) => {
        set({
          modalProps: {
            title,
            message,
            confirmLabel,
            cancelLabel,
            cancelText: cancelLabel,
            confirmText: confirmLabel,
            type: "confirm",
            message_type,
            layout,
            onConfirm,
            onCancel,
          }
        })
      }

    })
  )
);
