import { ContractFormType } from 'src/models/ContractFormType';
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { ApiAction } from 'src/models/ApiAction';
import { authenticatedApi, getAuthenticatedApiInstance } from 'src/Api/api';
import { useConfirmAlert } from './ConfirmAlertStore';
import { MissionStepKeys } from './CurrentMissionTypes/Types';
import { FileType, MissionContextType } from './CurrentMissionTypes/MissionContextType';
import { MissionType } from './CurrentMissionTypes/MissionType';
import { AxiosRequestConfig } from 'axios';


function getCallAction(set: any, get: any) {
  return async (action: ApiAction, data?: any, options?: {
    onStatusChange?: (status: 'needConfirmation' | 'loading' | 'success' | 'error') => void;
    returnData?: boolean;
    prefixIfSelfCare?: boolean;
    axiosRequestConfig?: AxiosRequestConfig<any>
  }) => {

    if (action.confirm) {
      options?.onStatusChange?.('needConfirmation');
      const confirm = await new Promise((resolve) => {

        if (!action.confirm) {
          resolve(true);
          return;
        }

        useConfirmAlert.getState().confirm({
          title: action.confirm.title,
          message: action.confirm.content,
          confirmLabel: action.confirm.confirmLabel,
          cancelLabel: action.confirm.cancelLabel,
          onConfirm: async () => {
            resolve(true);
          },
          onCancel: () => {
            return;
          }
        });

      });
      if (!confirm) {
        return;
      }
    }

    options?.onStatusChange?.('loading');
    let response;

    try {
      // No need to force prefixIfSelfCare for actions
      response = await getAuthenticatedApiInstance({ prefixIfSelfCare: options?.prefixIfSelfCare || false }).request({
        url: action.href,
        method: action.method,
        data,
        ...options?.axiosRequestConfig || {}
      });

      if (options?.returnData) {
        return response;
      }
    } catch (e) {
      get().refetchMission().catch(() => void 0);
      throw e;
    }

    if (!response.data.mission && !response.data.context) {
      options?.onStatusChange?.('error');
      throw new Error("missing mission in response");
    }

    if (response.data.mission) {
      set({ mission: response.data.mission as MissionType });
    }

    if (response.data.context) {
      set({ missionContext: response.data.context as MissionContextType });
    }
    options?.onStatusChange?.('success');

    return response;
  }
}

export const useCurrentMission = create<{
  missionContext: MissionContextType | null;
  mission: MissionType | null;
  fileCaseContractForm: ContractFormType[] | null;
  lockContractID: boolean | null;
  file: FileType | null;
  missionContextGoToStep: (stepKey: MissionStepKeys) => Promise<any>;
  missionContextGoBack: () => Promise<any>;
  canGoBack: () => boolean;
  refetchMission: () => Promise<any>;
  callAction: (
    action: ApiAction,
    data?: any,
    options?: {
      onStatusChange?: (status: 'needConfirmation' | 'loading' | 'success' | 'error') => void;
      returnData?: boolean;
      prefixIfSelfCare?: boolean;
      axiosRequestConfig?: AxiosRequestConfig<any>;
    }
  ) => Promise<any>;
  setMission: (mission: any) => void;
  setMissionContext: (missionContext: any) => void;
  setMissionCurrentStep: (currentStep: MissionStepKeys) => void;
  setFile: (file: any) => void;
  setAll: ({
    file,
    mission,
    missionContext,
    fileCaseContractForm,
    lockContractID,
  }: {
    file: FileType | null,
    mission: MissionType | null,
    missionContext: MissionContextType | null,
    fileCaseContractForm: Array<ContractFormType> | null,
    lockContractID: boolean | null,
  }) => void;

  isStepReadOnly: (stepKey: MissionStepKeys) => boolean;
  isCurrentStepReadOnly: () => boolean;

}>()(
  devtools(
    (set, get) => ({
      file: null,
      mission: null,
      missionContext: null,
      fileCaseContractForm: null,
      lockContractID: null,

      setMission: (mission: MissionType | null) => set({ mission }),
      setMissionContext: (missionContext: any) => {
        set({
          missionContext,
        })
      },
      setFile: (file: FileType | null) => set({ file }),

      setAll: ({
        file,
        mission,
        missionContext,
        fileCaseContractForm,
        lockContractID,
      }: {
        file: FileType | null,
        mission: MissionType | null,
        missionContext: MissionContextType | null,
        fileCaseContractForm: ContractFormType[] | null,
        lockContractID: boolean | null,
      }) => {
        set({
          file,
          mission,
          missionContext,
          fileCaseContractForm,
          lockContractID,
        })
      },

      setMissionCurrentStep: (currentStep: MissionStepKeys) => {
        const missionContext = get().missionContext;
        if (!missionContext) {
          return;
        }

        set({
          missionContext: {
            ...missionContext,
            currentStep,
          }
        });
      },

      missionContextGoToStep: async (stepKey: MissionStepKeys) => {
        const missionContext = get().missionContext;
        if (!missionContext) {
          return;
        }
        const newStep = missionContext.steps[stepKey as MissionStepKeys];

        if (!newStep) {
          return;
        }

        set({
          missionContext: {
            ...missionContext,
            currentStep: stepKey as MissionStepKeys,
          }
        });
      },

      refetchMission: async () => {
        const mission = get().mission;
        if (!mission) {
          return;
        }

        const response = await authenticatedApi.get(`/cases/${mission.caseID}/missions/${mission.id}`);
        if (!response.data.mission || !response.data.context) {
          throw new Error("missing mission in response");
        }

        set({
          mission: response.data.mission as MissionType,
          missionContext: response.data.context as MissionContextType,
        });
      },

      callAction: getCallAction(set, get),

      canGoBack: () => {
        // const missionContext = get().missionContext;
        // if (!missionContext) {
        //   return false;
        // }

        // const currentStepKey = missionContext.currentStep;
        // const currentStep = missionContext.steps[currentStepKey as MissionStepKeys];
        // if (!currentStep || !currentStep.previousStepKey) {
        //   return false;
        // }

        return true;
      },

      missionContextGoBack: async () => {
        const missionContext = get().missionContext;
        if (!missionContext) {
          return;
        }

        const currentStepKey = missionContext.currentStep;
        const currentStep = missionContext.steps[currentStepKey as MissionStepKeys];
        if (!currentStep || !currentStep.previousStepKey) {
          return;
        }

        const previousStepKey = currentStep.previousStepKey;

        return await get().missionContextGoToStep(previousStepKey);
      },

      isStepReadOnly: (stepKey: MissionStepKeys) => {
        const missionContext = get().missionContext;
        if (!missionContext) {
          return false;
        }
        if (missionContext?.steps?.confirmation) {
          return true;
        }
        return !!missionContext?.steps[stepKey]?.readOnly;
      },

      isCurrentStepReadOnly: () => {
        const missionContext = get().missionContext;
        if (!missionContext) {
          return false;
        }
        if (missionContext?.steps?.confirmation) {
          return true;
        }
        return get().isStepReadOnly(missionContext.currentStep);
      }
    })
  )
);
