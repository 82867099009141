import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { rootApi } from "src/Api/api";

interface FeatureFlagsState {
  features: Record<string, boolean>;
  setFeatures: (features: Record<string, boolean>) => void;
  fetchFeatureFlags: () => Promise<void>;
}

const useFeatureFlagsStore = create<FeatureFlagsState>()(
  devtools((set) => ({
    features: {},
    setFeatures: (features) => set({ features }),
    fetchFeatureFlags: async () => {
      try {
        const response = await rootApi.get("/api");
        set({ features: response.data.features });
      } catch (error) {
        console.error("feature flags error: ", error);
      }
    },
  }))
);

export default useFeatureFlagsStore;
