import ReactModal from 'react-modal';
import styles from './Modals.module.scss';
import { ChevronSelfCare, Close } from '@assets/icons/icons';
import { useMediaQuery } from 'usehooks-ts';
import { isSelfCare } from 'src/Stores/AppContext';
import { useEffect } from 'react';

export default function Modal({
  contentRef,
  onRequestClose,
  title,
  leftTitle,
  children,
  disableBodyScroll,
  disableModalScroll,
  containerProps: { className, ...containerProps } = {},
  contentContainerProps: { className: contentClassName, ...contentContainerProps } = {},
  layout = 'default',
  style,
}: {
  contentRef?: React.RefObject<HTMLDivElement>;
  onRequestClose?: () => any;
  title?: string | React.ReactNode;
  leftTitle?: React.ReactNode;
  children?: React.ReactNode;
  disableBodyScroll?: boolean;
  disableModalScroll?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  contentContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  layout?: 'default' | 'minimal';
  style?: ReactModal.Styles | undefined;
}) {
  // use mediaQuery to change the style of the modal
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (isMobile || disableBodyScroll) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    }
  }, [isMobile, disableBodyScroll])

  return (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={{
        ...style,
        overlay: {
          ...style?.overlay,
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 8000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          ...style?.content,
          overflow: disableModalScroll ? "inherit" : "auto",
          border: "none",
          padding: 0,
          inset: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
   

          ...(((isMobile || isSelfCare()) && layout === 'default') ? {
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 0,
            } : {
              borderRadius: 10,
              maxHeight: "calc(100vh - 40px)",
            }
          ),

          ...(layout === 'minimal' ? {
            marginLeft: '20px',
            marginRight: '20px',
          } : {})
        }
      }}
      onRequestClose={() => {
        onRequestClose && onRequestClose();
      }}
    >
      <div className={[
        styles.container,
        isMobile ? styles.mobile : "",
        styles[`layout_${layout}`],
        disableModalScroll ? styles.disableModalScroll : "",
        className
      ].join(" ")} {...containerProps}>

        <div className={styles.header}>
          {layout !== 'minimal' && (
          <div className={[styles.closeContainer , 'closeContainer'].join(" ")}>
            {leftTitle || <div className={styles.leftTitle} />}
            <div className={styles.close} onClick={() => {
              onRequestClose && onRequestClose();
            }}>
              {isSelfCare() && (<ChevronSelfCare />)}
              {!isSelfCare() && (<Close />)}
            </div>
          </div>
          )}

          {title && (
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {title}
            </div>
          </div>
          )}

        </div>

        <div
          {...contentContainerProps}
          className={`${styles.content} ${contentClassName || ""}`}
          ref={contentRef}
        >
          {children}
        </div>
      </div>
    </ReactModal>
  )
}