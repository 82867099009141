import TextEllipsisWithPopup from "@components/TextEllipsisWithPopup/TextEllipsisWithPopup";
import styles from "./NotificationDetail.module.scss";
import Button from "@components/Button/Button";
import { getTransportationIcon } from "@components/Itinerary/helpers";
import Tag from "@components/Tag/Tag";
import { statusLabels } from "src/helpers/statusLabels";
import HourPicker from "@components/HourPicker/HourPicker";
import DatePicker from "@components/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import { NotificationType, History } from "src/Stores/NotificationsTypes";
import { useEffect, useState } from "react";
import { addHours, isBefore } from "date-fns";
import { formatDate } from "src/helpers/date";
import { authenticatedApi } from "src/Api/api";
import { useNavigate } from "react-router-dom";
import { useConfirmAlert } from "src/Stores/ConfirmAlertStore.ts";

type NotificationDetailProps = {
  onCloseDetail: () => void;
  onSaveDetail: (item: NotificationType) => void;
  item: NotificationType;
};

const NotificationDetail = ({
  onCloseDetail,
  onSaveDetail,
  item,
}: NotificationDetailProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { confirm } = useConfirmAlert();
  const [selectedPriority, setSelectedPriority] = useState<string>(
    item.errorPriority
  );
  const listPriority = ["low", "medium", "high"];
  const today = new Date();
  const deadlineDate = new Date(item.deadline);
  const isPastDeadline = isBefore(deadlineDate, today);
  const defaultDate = isPastDeadline ? today : deadlineDate;
  const [history, setHistory] = useState<null | History[]>(null);
  const [ownsLock, setOwnsNotificationLock] = useState<boolean>(false);
  const [isFullHistory, setIsFullHistory] = useState<boolean>(false);
  const historyLengthDisplay = 2;

  const roundToNextQuarterHour = (minutes: number) =>
    Math.ceil(minutes / 15) * 15;

  const defaultHour = isPastDeadline
    ? addHours(today, 1).getHours()
    : deadlineDate.getHours();

  const defaultMinutes = isPastDeadline
    ? roundToNextQuarterHour(today.getMinutes()) % 60
    : roundToNextQuarterHour(deadlineDate.getMinutes()) % 60;

  const [selectedDate, setSelectedDate] = useState<Date | null>(defaultDate);
  const [selectedHour, setSelectedHour] = useState<number | null>(defaultHour);
  const [selectedMinutes, setSelectedMinutes] = useState<number | null>(
    defaultMinutes
  );
  const [newDeadline, setNewDeadLine] = useState<string | null>(null);

  const updateDeadline = (
    date: Date | null,
    hour: number | null,
    minutes: number | null
  ) => {
    if (!date) return;

    const updatedDate = new Date(date);
    if (hour !== null) updatedDate.setHours(hour);
    if (minutes !== null) updatedDate.setMinutes(minutes);
    updatedDate.setSeconds(0);
    updatedDate.setMilliseconds(0);

    const tzOffset = -updatedDate.getTimezoneOffset();
    const sign = tzOffset >= 0 ? "+" : "-";
    const hoursOffset = String(Math.floor(Math.abs(tzOffset) / 60)).padStart(
      2,
      "0"
    );
    const minutesOffset = String(Math.abs(tzOffset) % 60).padStart(2, "0");
    const timezone = `${sign}${hoursOffset}:${minutesOffset}`;

    const formattedDeadline = `${updatedDate.getFullYear()}-${(
      updatedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${updatedDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${updatedDate
          .getHours()
          .toString()
          .padStart(2, "0")}:${updatedDate
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${updatedDate
              .getSeconds()
              .toString()
              .padStart(2, "0")}${timezone}`;

    setNewDeadLine(formattedDeadline);
  };

  const goToResolve = () => {
    navigate(item.navs[0].href, { replace: true });
  };

  const fetchHistory = async () => {
    try {
      const response = await authenticatedApi.request({
        url: `/api/v1/notification/${item.id}`,
        method: "GET",
      });
      setHistory(response?.data?.timeBlocks);
      setOwnsNotificationLock(response?.data?.ownsLock);
    } catch (error) {
      console.error(error);
    }
  };

  const saveNotif = async () => {
    try {
      const dataToUpdate = {
        data: {
          id: String(item.id),
          errorPriority: selectedPriority,
          deadline: newDeadline,
        },
      };

      const response = await authenticatedApi.request({
        url: `/api/v1/notification/${item.id}`,
        method: "PUT",
        data: dataToUpdate,
      });
      const savedItem = response?.data;
      onSaveDetail(savedItem);
      onCloseDetail();
    } catch (error) {
      console.error(error);
    }
  };

  const isErrorStatus = (status: string) => {
    const errorStatus = [
      "error",
      "canceled",
      "canceled_by_driver",
      "canceled_by_assister",
      "unacknowleged",
    ];
    return errorStatus.includes(status);
  };

  const handleSave = async () => {
    await confirm({
      title: t("confirmNotificationTitle"),
      message: t("confirmNotificationTMessage"),
      confirmLabel: t("optionOui"),
      cancelLabel: t("optionNon"),
      onConfirm: async () => {
        saveNotif();
      },
      onCancel: () => void 0,
    });
  };

  useEffect(() => {
    fetchHistory();
    updateDeadline(selectedDate, selectedHour, selectedMinutes);
  }, []);

  return (
    <div className={styles.notificationDetail}>
      <div className={styles.notificationHeader}>
        <p className={styles.notificationDate}> {formatDate(item.deadline)}</p>
        <p className={styles.notificationId}>
          <span>
            {" "}
            {item.missionId} -{" "}
            <span className="inline-block uppercase">{item.lastname}</span>
          </span>
        </p>
        {item.isSolved || (
          <Button
            type="primary"
            label={t("open")}
            size="small"
            onClick={goToResolve}
            containerProps={{
              disabled: !ownsLock
            }}
          />
        )}
      </div>

      <div className={styles.historySection}>
        <h3 className={styles.sectionTitle}>{t("history")}</h3>
        <div className={styles.historyList}>
          {history
            ?.slice(0, isFullHistory ? history.length : historyLengthDisplay)
            .map((historyItem) => (
              <div key={historyItem.dateTime} className={styles.historyItem}>
                <p className={styles.historyDate}>
                  {formatDate(historyItem.dateTime)}
                </p>

                {historyItem?.rows?.map((row) => (
                  <div className={`${styles.itemLine} `}>
                    <div
                      className={`${styles.historyTravel} ${isErrorStatus(row?.status?.id) ? styles.error : ""
                        } `}
                    >
                      {getTransportationIcon(row.transportType)}
                      <TextEllipsisWithPopup>
                        {row.destination
                          ? `${row.origin} > ${row.destination}`
                          : row.origin}
                      </TextEllipsisWithPopup>
                    </div>{" "}
                    <Tag
                      label={row.status?.label}
                      color={statusLabels[row.status?.id]?.color}
                      containerProps={{
                        className: styles.tagStatus,
                        style: { fontSize: "14px" },
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
        </div>

        {history && history.length > historyLengthDisplay && (
          <a
            href="#"
            className={styles.viewAllHistory}
            onClick={() => setIsFullHistory(!isFullHistory)}
          >
            {isFullHistory ? t("viewLess") : t("viewFullHistory")}
          </a>
        )}
      </div>

      <div className={styles.modifySection}>
        {item.isSolved || <h3 className={styles.sectionTitle}> {t("edit")}</h3>}
        <div className={styles.modifyChoice}>
          <div className={styles.choiceTitle}> {t("priority")}</div>
          <div className={styles.priorityOptions}>
            {listPriority.map(
              (priority) =>
                (!item.isSolved || selectedPriority === priority) && (
                  <label
                    onClick={() => setSelectedPriority(priority)}
                    key={priority}
                    className={`${styles.priorityLabel} ${selectedPriority === priority ? styles.selected : ""
                      }`}
                  >
                    <div
                      className={`${styles.priorityDot} ${styles[priority] || ""
                        }`}
                    ></div>
                    {t(priority)}
                  </label>
                )
            )}
          </div>
        </div>

        <div className={styles.modifyChoice}>
          <div className={styles.choiceTitle}> {t("dueDate")}</div>
          <div className={styles.dateChoice}>
            <DatePicker
              minDate={today}
              date={selectedDate}
              disabled={item.isSolved}
              onChange={(date) => {
                console.log("date", date);
                setSelectedDate(date);
                updateDeadline(date, selectedHour, selectedMinutes);
              }}
            />
            <span className={styles.dateHourSeparator}>{t("at")}</span>
            <HourPicker
              disabled={item.isSolved}
              hour={selectedHour}
              minutes={selectedMinutes}
              onChange={({ hour, minutes }) => {
                setSelectedHour(hour);
                setSelectedMinutes(minutes);
                updateDeadline(selectedDate, hour, minutes);
              }}
            />
          </div>
        </div>
      </div>

      {item.isSolved || (
        <div className={styles.actionButtons}>
          <Button
            type="secondary"
            label={t("button.cancel")}
            size="small"
            onClick={onCloseDetail}
          />
          <Button
            type="primary"
            label={t("button.save")}
            size="small"
            onClick={() => handleSave()}
            containerProps={{
              disabled: !ownsLock
            }}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationDetail;
