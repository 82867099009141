import {useEffect} from "react";
import styles from "./Notifications.module.scss";
import {
  IconBell,
  IconMenuLeft,
  IconMenuRight,
  Chevron,
} from "@assets/icons/icons";
import { useTranslation } from "react-i18next";
import { authenticatedApi } from "src/Api/api";
import TextEllipsisWithPopup from "@components/TextEllipsisWithPopup/TextEllipsisWithPopup";
import Tag from "@components/Tag/Tag";
import { NotificationType, FiltersType } from "src/Stores/NotificationsTypes";
import Button from "@components/Button/Button";
import { statusLabels } from "src/helpers/statusLabels";
import { getTransportationIcon } from "@components/Itinerary/helpers";
import { IconChevron, IconFilter } from "@assets/icons/icons";
import Checkbox from "@components/Checkbox/Checkbox";
import NotificationDetail from "./NotificationDetail";
import { Oval } from "react-loader-spinner";
import { formatDate } from "src/helpers/date";
import { useLocation, useNavigate } from "react-router-dom";
import useNotificationsStore from "src/Stores/NotificationsStore";
import useFeatureFlagsStore from "src/Stores/FeatureFlagsStore.tsx";

export const Notifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { features } = useFeatureFlagsStore();
  const showNotifications = features?.NOTIFICATIONS;
  const {
    notificationsOpened,
    setNotificationsOpened,
    activeTab,
    setActiveTab,
    notifList,
    setNotifList,
    filters,
    setFilters,
    showFilters,
    setShowFilters,
    countNotSolved,
    countSolved,
    filteredCount,
    showDetail,
    setShowDetail,
    loadingNotif,
    currentNotifDetail,
    setCurrentNotifDetail,
    params,
    setParams,
    filterKey,
    setFilterKey,
    fetchNotifications,
  } = useNotificationsStore();
  const isNotSolvedList = activeTab === "not-solved";
  const showMoreButton =
    (isNotSolvedList && notifList.length < (filteredCount || 0)) ||
    (!isNotSolvedList && notifList.length < (filteredCount || 0));

  const fetchFilters = async () => {
    const response = await authenticatedApi.request({
      url: `/api/v1/metadata/notifications`,
      method: "GET",
    });
    const filters: FiltersType = response?.data?.fields;
    setFilters(filters);
    initParams(filters);
  };

  const initParams = (filters: FiltersType) => {
    const errorPriority = filters?.errorPriority?.options || [];
    const reason = filters?.reason?.options || [];

    setParams({
      ...params,
      errorPriority: errorPriority.reduce<Record<string, boolean>>(
        (acc, option) => {
          acc[option.id] = false;
          return acc;
        },
        {}
      ),
      reason: reason.reduce<Record<string, boolean>>((acc, option) => {
        acc[option.id] = false;
        return acc;
      }, {}),
    });
  };

  const toggleIsOpen = () => {
    setNotificationsOpened(!notificationsOpened);
  };

  const handleTabClick = (type: "solved" | "not-solved") => {
    if (type !== activeTab) {
      setNotifList([]);
      setActiveTab(type);
      setParams({
        ...params,
        isSolved: type,
        offset: 0,
      });
      setFilterKey(filterKey + 1);
    }
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    if (!filters) {
      fetchFilters();
    }
  };

  const handleShowDetail = (item: NotificationType) => {
    setCurrentNotifDetail(item);
    setShowDetail(true);
  };

  const updateList = () => {
    fetchNotifications();
  };
  const backToList = () => {
    setShowDetail(false);
  };

  const loadMoreNotifications = () => {
    setParams({
      ...params,
      offset: params.offset + 10,
    });
  };
  const toggleSortDueDate = () => {
    console.log("params", params);
    setParams({
      ...params,
      order: params.order === "asc" ? "desc" : "asc",
    });
    setFilterKey(filterKey + 1);
  };

  const getMissionId = (): number | null => {
    const match = location.pathname.match(/\/missions\/(\d+)/);
    return match ? parseInt(match[1], 10) : null;
  };

  const missionId = getMissionId();

  useEffect(() => {
    fetchNotifications();
  }, [params, filterKey]);

  if (!showNotifications) {
    return null;
  }

  return (
    <div
      className={`${styles.notifications} ${
        notificationsOpened ? styles.open : ""
      }`}
    >
      <div
        className={styles.notificationHeader}
        onClick={showDetail ? backToList : toggleIsOpen}
      >
        <div className={styles.icons}>
          {showDetail ? (
            <div className={styles.backArrow}>
              <IconChevron />
            </div>
          ) : (
            <div>
              {!!countNotSolved && countNotSolved > 0 && (
                <div className={styles.count}>{countNotSolved}</div>
              )}
              <IconBell />
            </div>
          )}
          {!notificationsOpened && <IconMenuLeft />}
        </div>
        <div className={styles.notificationHeaderTitle}>
          {t("notifications")}
          <IconMenuRight
            onClick={(event) => {
              event.stopPropagation();
              toggleIsOpen();
              backToList();
            }}
          />
        </div>
      </div>

      {showDetail && currentNotifDetail ? (
        <NotificationDetail
          item={currentNotifDetail}
          onCloseDetail={() => setShowDetail(false)}
          onSaveDetail={() => updateList()}
        />
      ) : (
        <div className={styles.notificationContent}>
          <div className={styles.notificationTabs}>
            <div
              className={`${styles.tab} ${
                activeTab === "solved" ? styles.active : ""
              }`}
              onClick={() => handleTabClick("solved")}
            >
              {t("resolved")}
              {typeof countSolved === "number" && ` (${countSolved})`}
            </div>
            <div
              className={`${styles.tab} ${
                activeTab === "not-solved" ? styles.active : ""
              }`}
              onClick={() => handleTabClick("not-solved")}
            >
              {t("unresolved")}{" "}
              {typeof countNotSolved === "number" && ` (${countNotSolved})`}
            </div>
          </div>

          <div className={styles.topContent}>
            <div className={styles.topContentSort} onClick={toggleSortDueDate}>
              {t("dueDate")}
              <div
                style={{
                  transform:
                    params.order === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                <Chevron width={14} />
              </div>
            </div>
            {isNotSolvedList && (
              <div className={styles.iconFilter} onClick={handleShowFilters}>
                <IconFilter />
              </div>
            )}
          </div>
          {showFilters && filters && isNotSolvedList && (
            <div className={styles.filters}>
              <div className={styles.filter}>
                <div className={styles.filterTitle}>
                  {t("filter_by")} {filters.errorPriority?.label}
                </div>
                {filters.errorPriority?.options.map((field) => (
                  <div className="flex items-center gap-2" key={field.id}>
                    <Checkbox
                      label={
                        <div className="flex items-center gap-2">
                          <div
                            className={`${styles.priorityDot} ${
                              styles[field.id] || ""
                            }`}
                          />
                          {field.label}
                        </div>
                      }
                      checked={params?.errorPriority?.[field.id] || false}
                      onChange={(checked) => {
                        setParams({
                          ...params,
                          errorPriority: {
                            ...params.errorPriority,
                            [field.id]: checked,
                          },
                          offset: 0,
                        });
                        setFilterKey(filterKey + 1);
                      }}
                    />
                  </div>
                ))}
              </div>

              <div className={styles.filter}>
                <div className={styles.filterTitle}>
                  {t("filter_by")} {filters.reason?.label}
                </div>

                {filters.reason?.options.map((field) => (
                  <div key={field.id}>
                    <Checkbox
                      label={field.label}
                      checked={params?.reason?.[field.id] || false}
                      onChange={(checked) => {
                        setParams({
                          ...params,
                          reason: {
                            ...params.reason,
                            [field.id]: checked,
                          },
                          offset: 0,
                        });
                        setFilterKey(filterKey + 1);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles.notificationList}>
            {loadingNotif ? (
              <div className={styles.loader}>
                <Oval
                  height={32}
                  width={32}
                  color="black"
                  secondaryColor="black"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              notifList?.map((item) => (
                <div
                  key={item.id}
                  className={`${styles.item} ${
                    item.missionId === missionId ? styles.active : ""
                  }`}
                  onClick={() => handleShowDetail(item)}
                >
                  <div className={styles.itemDeadline}>
                    {formatDate(item.deadline)}
                  </div>
                  <div className={styles.itemLine}>
                    <div
                      className={`${styles.priorityDot} ${
                        styles[item.errorPriority] || ""
                      }`}
                    ></div>
                    <div className={styles.infoCase}>
                      {item.missionId} -{" "}
                      <span className="inline-block uppercase">
                        {item.lastname}
                      </span>
                    </div>
                      <Button
                        onClick={(e) => {
                          navigate(item.navs[0]?.href);
                          e.stopPropagation();
                        }}
                        type="primary"
                        label="open"
                        size="xsmall"
                        containerProps={{ className: styles.button }}
                      />
                  </div>
                  <div className={styles.itemLine}>
                    <div className={styles.itemTravel}>
                      {getTransportationIcon(item.transportType)}
                      <TextEllipsisWithPopup>
                        {item.destination
                          ? `${item.origin} > ${item.destination}`
                          : item.origin}
                      </TextEllipsisWithPopup>
                    </div>
                  </div>
                  <Tag
                    label={item.reason?.label}
                    color={statusLabels[item.reason?.id]?.color}
                    containerProps={{
                      className: styles.tagStatus,
                      style: { fontSize: "14px" },
                    }}
                  />
                  <div className={styles.chevron}>
                    <IconChevron />
                  </div>
                </div>
              ))
            )}
            {showMoreButton && (
              <div className={"flex m-4"}>
                <Button
                  containerProps={{
                    className: styles.tagStatus,
                    style: { flex: "1" },
                  }}
                  type="secondary"
                  label={t("viewMore")}
                  size="xsmall"
                  onClick={loadMoreNotifications}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
