import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your local translation files
import translationEN from './en/translation.json';
import translationFR from './fr/translation.json';

// Resources object containing your translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", // Langue de secours
    debug: true,
    detection: {
      order: ["localStorage", "cookie", "querystring", "navigator", "htmlTag"], // Priorise le localStorage
      lookupLocalStorage: "i18nextLng", // Vérifie si `i18nextLng` est défini dans localStorage
      caches: ["localStorage"], // Stocke la langue dans localStorage
      excludeCacheFor: ["cimode"], // Exclut certaines langues
    },
    interpolation: {
      escapeValue: false, // React gère déjà l'échappement
    },
  });

// eslint-disable-next-line
// @ts-ignore
window.window_i18n = i18n;

export default i18n;
