import { create } from "zustand";
import { NotificationType, FiltersType } from "./NotificationsTypes";
import {authenticatedApi} from "../Api/api.ts";

interface ParamsType {
    isSolved: "solved" | "not-solved";
    errorPriority?: Record<string, boolean>;
    reason?: Record<string, boolean>;
    offset: number;
    order: "asc" | "desc";
}

interface NotificationsStore {
    notificationsOpened: boolean;
    setNotificationsOpened: (value: boolean) => void;
    activeTab: "solved" | "not-solved";
    setActiveTab: (value: "solved" | "not-solved") => void;
    notifList: NotificationType[];
    setNotifList: (list: NotificationType[]) => void;
    filters: FiltersType | null;
    setFilters: (filters: FiltersType | null) => void;
    showFilters: boolean;
    setShowFilters: (value: boolean) => void;
    countNotSolved: number | null;
    setCountNotSolved: (value: number | null) => void;
    countSolved: number | null;
    setCountSolved: (value: number | null) => void;
    filteredCount: number | null;
    setFilteredCount: (value: number | null) => void;
    showDetail: boolean;
    setShowDetail: (value: boolean) => void;
    loadingNotif: boolean;
    setLoadingNotif: (value: boolean) => void;
    notifInit: boolean;
    setNotifInit: (value: boolean) => void;
    currentNotifDetail: NotificationType | null;
    setCurrentNotifDetail: (detail: NotificationType | null) => void;
    params: ParamsType;
    setParams: (value: ParamsType) => void;
    filterKey: number;
    setFilterKey: (value: number) => void;
    fetchNotifications: () => Promise<void>;
    reloadNotifications: () => Promise<void>;
}

const useNotificationsStore = create<NotificationsStore>()(
        (set, get) => ({
            notificationsOpened: false,
            setNotificationsOpened: (value) => set({ notificationsOpened: value }),

            // Initializing new states
            activeTab: "not-solved",
            setActiveTab: (value) => set({ activeTab: value }),
            notifList: [],
            setNotifList: (list) => set({ notifList: list }),
            filters: null,
            setFilters: (filters) => set({ filters }),
            showFilters: false,
            setShowFilters: (value) => set({ showFilters: value }),
            countNotSolved: null,
            setCountNotSolved: (value) => set({ countNotSolved: value }),
            countSolved: null,
            setCountSolved: (value) => set({ countSolved: value }),
            filteredCount: null,
            setFilteredCount: (value) => set({ filteredCount: value }),
            showDetail: false,
            setShowDetail: (value) => set({ showDetail: value }),
            loadingNotif: false,
            setLoadingNotif: (value) => set({ loadingNotif: value }),
            notifInit: false,
            setNotifInit: (value) => set({ notifInit: value }),
            currentNotifDetail: null,
            setCurrentNotifDetail: (detail) => set({ currentNotifDetail: detail }),
            params: {
                isSolved: "not-solved",
                offset: 0,
                order: "asc",
            },
            setParams: (value) => set({ params: value }),
            filterKey: 0,
            setFilterKey: (value) => set({ filterKey: value }),
            reloadNotifications: async() => {
                set({ params: { ...get().params, offset: 0 }, notifList: []})
                await get().fetchNotifications();
            },
            fetchNotifications: async () => {
                const params = get().params;
                const queryParams = new URLSearchParams();
                const solvedParam = params.isSolved;
                queryParams.append("isSolved", params.isSolved);
                queryParams.append("offset", params.offset.toString());
                queryParams.append("orderBy", "deadline");
                queryParams.append("order", params.order);

                if (params.errorPriority) {
                    Object.entries(params.errorPriority).forEach(([key, value]) => {
                        if (value) {
                            queryParams.append("errorPriority", key);
                        }
                    });
                }
                if (params.reason) {
                    Object.entries(params.reason).forEach(([key, value]) => {
                        if (value) {
                            queryParams.append("reason", key);
                        }
                    });
                }
                try {
                    get().setLoadingNotif(true);
                    const response = await authenticatedApi.request({
                        url: `/api/v1/notifications?${queryParams.toString()}`,
                        method: "GET",
                    });
                    const count = response?.data?.total || 0;

                    if (solvedParam === "not-solved") {
                        get().setCountNotSolved(count);
                    } else {
                        get().setCountSolved(count);
                    }
                    get().setFilteredCount(count);
                    const result = response?.data?.rows || [];

                    get().setNotifList(params.offset === 0 ? result : [...get().notifList, ...result]);
                    get().setNotifInit(true);
                } catch (error) {
                    console.error(error);
                } finally {
                    get().setLoadingNotif(false);
                }
            }
        })
);

export default useNotificationsStore;
