import { useTranslation } from 'react-i18next'; // Add import statement for useTranslation
import { Oval } from 'react-loader-spinner';
import stylesCabTravelPath from './CabTravelPath.module.scss';
import { Add } from '@assets/icons/icons';
import { useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { statusLabels } from 'src/helpers/statusLabels';
import Tag from '@components/Tag/Tag';
import { ApiAction } from 'src/models/ApiAction';
import { isSectionTypeConfirm, isSectionTypeRecap } from './helpers';
import RecapTravelPathSection from './Components/RecapTravelPathSection/RecapTravelPathSection';
import { ConfirmSectionType, RecapSectionType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import formatDuration from 'src/helpers/duration';
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';
import EditTaxiButton from './Components/EditTaxi/EditTaxiButton';
import RebookTaxiButton from './Components/RebookTaxi/RebookTaxiButton';
import Checkbox from '@components/Checkbox/Checkbox';
import Button from "@components/Button/Button.tsx";

export default function CabTravelPath({
  section,
  containerProps: { ...containerProps } = {},
}: {
  section: RecapSectionType | ConfirmSectionType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const { confirm, setModalProps, alert } = useConfirmAlert();
  const { callAction: callMissionAction } = useCurrentMission();
  const [loading, setLoading] = useState(false);
  const rebookTaxiAction = isSectionTypeConfirm(section) ? section.rebookTaxiAction : null;
  const rebookTaxiLimits = isSectionTypeConfirm(section) ? section.rebookTaxiLimits : null;

  const callAction = async (action: ApiAction) => {
    setLoading(true);
    try {
      await callMissionAction(action);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteTaxi = async () => {
    if (isSectionTypeRecap(section)) {
      if (!section.actions.removeConnectingTaxi) {
        return;
      }
      await callAction(section.actions.removeConnectingTaxi);
    } else if (isSectionTypeConfirm(section)) {
      if (section.cancelActions) { // TODO: deduplicate code with RecapItinerary.tsx
        return alert({
          title: t('cancel_service'),
          message: (<div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '10px' }}>
            {(section.cancelActions.map(action => {
              return <Button onClick={async () => {
                await callAction(action.action);
                setModalProps(null);
              }}>{action.label}</Button>
            }))}
          </div>),
          confirmLabel: t('close'),
          confirmType: 'secondary',
          onClose: () => void 0,
        });
      }
      if (!section.cancelAction) {
        return;
      }
      await confirm({
        title: t('cancel_service'),
        message: t('cancel_service_confirm'),
        confirmLabel: t('optionOui'),
        cancelLabel: t('optionNon'),
        onConfirm: async () => {
          if (!isSectionTypeConfirm(section) || !section.cancelAction) {
            return;
          }
          callAction(section.cancelAction);
        },
        onCancel: () => void 0,
      });
    }
  };

  const addTaxi = async () => {
    if (!isSectionTypeRecap(section)) {
      return false;
    }
    if (!section.actions.addConnectingTaxi) {
      return;
    }

    await Promise.all([
      callAction(section.actions.addConnectingTaxi)
    ]);
  }

  let rightContent: React.ReactNode = null;
  if (loading) {
    rightContent = (
        <div className={stylesCabTravelPath.travelInfoContainer}>
          <div className={stylesCabTravelPath.textInfosContainer}>
            <div className={stylesCabTravelPath.addContainer}>
              <Oval
                  height={18}
                  width={18}
                  color="black"
                  secondaryColor="black"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
              />
            </div>
          </div>
        </div>
    );
  } else if (isSectionTypeRecap(section) && !!section.actions?.addConnectingTaxi) {
    rightContent = (
        <div className={stylesCabTravelPath.travelInfoContainer}>
          <div className={stylesCabTravelPath.textInfosContainer}>
            <div className={stylesCabTravelPath.addContainer} onClick={() => {
              addTaxi();
            }}>
              <Add/>
              {t('addTaxi')}
            </div>
          </div>
        </div>
    );
  } else if (isSectionTypeRecap(section)) {
    rightContent = (
        <div className={stylesCabTravelPath.travelInfoContainer}>
          <div className="flex flex-row justify-start items-center">
            <div className={stylesCabTravelPath.textInfosContainer}>
              <div className={stylesCabTravelPath.infoSecondaryLabel}>
                {formatDuration({
                  value: section.durationMinutes,
                  unit: 'minutes',
                })}
              </div>
            </div>
          </div>

          {!!section.price && (
              <div className={stylesCabTravelPath.priceContainer}>
                {section.statusWithLabel &&
                    section.statusWithLabel.isDisplayed &&
                    section.statusWithLabel.id &&
                    statusLabels[section.statusWithLabel.id] && (
                        <Tag
                            {...(section.statusWithLabel.href
                                ? {
                                  onClick: () => {
                                    window.open(section.statusWithLabel.href, '_blank');
                                  },
                                }
                                : {})}
                            label={section.statusWithLabel.label}
                            color={
                                statusLabels[section.statusWithLabel.id]?.color || 'grey'
                            }
                            containerProps={{
                              className: stylesCabTravelPath.tagRecapStatus,
                            }}
                        />
                    )}

                <div className={stylesCabTravelPath.price}>
                  {section.priceIsEstimate ? t('estimatedPricePrefix') : ''}
                  {section.price.formatted} {section.price.currency.symbol}
                </div>

                {isSectionTypeRecap(section) && !!section.actions.rescheduleTaxi && (
                    <EditTaxiButton
                        currentScheduledAt={section.origin.datetime}
                        rescheduleTaxiLimits={section.rescheduleTaxiLimits}
                        rescheduleTaxiAction={section.actions.rescheduleTaxi}
                    />
                )}

                {isSectionTypeRecap(section) && !!section.actions.removeConnectingTaxi && (
                    <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          deleteTaxi();
                          return false;
                        }}
                        className={stylesCabTravelPath.delete}
                    >
                      {t('removeTaxi')}
                    </a>
                )}
              </div>
          )}
        </div>
    );
  } else if (isSectionTypeConfirm(section)) {
    rightContent = (
        <div className={stylesCabTravelPath.travelInfoContainer}>
          <div className="flex flex-row justify-start items-center">
            <div className={stylesCabTravelPath.textInfosContainer}>
              <div className={stylesCabTravelPath.infoSecondaryLabel}>
                {formatDuration({
                  value: section.durationMinutes,
                  unit: 'minutes',
                })}
              </div>
              {section.providerLabel && (
                  <div className={stylesCabTravelPath.infoSecondaryLabel}>{section.providerLabel}</div>
              )}
            </div>
          </div>

          <div className={stylesCabTravelPath.priceContainer}>
            {section.statusWithLabel &&
                section.statusWithLabel.isDisplayed &&
                section.statusWithLabel.id &&
                statusLabels[section.statusWithLabel.id] && (
                    <Tag
                        {...(section.statusWithLabel.href
                            ? {
                              onClick: () => {
                                window.open(section.statusWithLabel.href, '_blank');
                              },
                            }
                            : {})}
                        label={section.statusWithLabel.label}
                        color={
                            statusLabels[section.statusWithLabel.id]?.color || 'grey'
                        }
                        containerProps={{
                          className: stylesCabTravelPath.tagRecapStatus,
                        }}
                    />
                )}
            {!!section.price && (
                <div className={stylesCabTravelPath.price}>
                  {section.priceIsEstimate ? t('estimatedPricePrefix') : ''}
                  {section.price.formatted} {section.price.currency.symbol}
                </div>
            )}

            {rebookTaxiAction && (
                <RebookTaxiButton
                    currentScheduledAt={section.origin.datetime}
                    rescheduleTaxiLimits={rebookTaxiLimits!}
                    rescheduleTaxiAction={rebookTaxiAction}
                />
            )}

            {isSectionTypeConfirm(section) && !!section.cancelAction && (
                <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      deleteTaxi();
                      return false;
                    }}
                    className={stylesCabTravelPath.delete}
                >
                  {t('cancel')}
                </a>
            )}
          </div>
        </div>
    );
  }

  return (
    <>
      <RecapTravelPathSection
        section={section}
        containerProps={{
          ...containerProps,
          className: `${containerProps.className
            } ${stylesCabTravelPath.travelPath}`,
        }}
        right={rightContent}
      />

      {/* add a checkbox */}
      {isSectionTypeRecap(section) && (!!section.actions.asCab || !!section.actions.asConnectingCab) && (
        <div className={stylesCabTravelPath.checkboxContainer}>
          <Checkbox
            label={t('consideredAsConnectingCab')}
            checked={!section.actions.asConnectingCab}
            onChange={async () => {
              if (section.actions.asConnectingCab) {
                await callAction(section.actions.asConnectingCab);
              } else if (section.actions.asCab) {
                await callAction(section.actions.asCab);
              }
            }}
          />
        </div>
      )}
    </>
  );
}
