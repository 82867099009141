import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './SettingsPage.module.scss';
import { useTranslation } from 'react-i18next';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';
import Select from '@components/Select/Select';
import { useState } from 'react';
import i18n from 'src/Locales/i18n';
import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';


export default function SettingsPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t,  } = useTranslation();
  const navigate = useNavigate();

  const language: any = i18n.language;

  const [selectedLang, setSelectedLang] = useState<string | null>(language);

  const langs = [
    { id: 'fr-FR', label: t('french') },
    { id: 'en-US', label: t('english') },
  ];

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [styles.container, className].join(" "),
      }}
    >

      <HeaderV2 />

      <div className={styles.content}>

        <div className={styles.formTitleLine}>
          <div className={styles.formTitle}>{t('settings')}</div>
        </div>

        <div className={styles.form}>
          <Select
            label={t('lang')}
            optionsV2={langs.map((lang) => ({
              label: lang.label,
              value: lang.id,
            }))}
            selectedIndex={langs.findIndex((lang) => lang.id === selectedLang)}
            onChangeIndex={(index) => {
              if (index === null) {
                setSelectedLang(null);
                return;
              }
              i18n.changeLanguage(langs[index].id);
              setSelectedLang(langs[index].id)
              window.location.reload();
            }}
            containerProps={{
              className: styles.selectMultiple,
            }}
            valueClassName={styles.selectMultipleValue}
          />

        </div>

        <div>
          <Button
            label={t('back_button')}
            onClick={() => {
              navigate(-1);
            }}
            type='secondary'
            containerProps={{
              className: 'mt-4',
            }}
          />
        </div>



      </div>

    </PageContainer>
  )
}