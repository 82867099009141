import styles from './Button.module.scss';

export type ButtonPropsType = {
  label?: React.ReactNode;
  type?: 'primary' | 'secondary';
  size?: 'normal' | 'small' | 'xsmall';
  colorType?: 'default' | 'success' | 'error';
  bigBorder?: boolean;
  testid?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  children?: React.ReactNode;
  containerProps?: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;
  disableUpperCase?: boolean;
};

export default function Button({
  label = "",
  type = "primary",
  colorType = "default",
  bigBorder,
  disableUpperCase,
  size = 'normal',
  onClick,
  testid,
  children,
  containerProps: { className, type: buttonType, ...containerProps } = {},
}: ButtonPropsType) {
  return (
    <button
      data-testid={testid}
      className={[
        styles.container,
        className,
        type === "secondary" ? styles.secondary : "",
        styles["colortype_" + colorType],
        styles["size_" + size],
        disableUpperCase ? styles.disableUpperCase : "",
        bigBorder ? styles.bigBorder : "",
      ].join(" ")}
      type={buttonType || "button"}
      {...containerProps}
      onClick={onClick}
    >
      {children || label}
    </button>
  )
}