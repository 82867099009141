import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import i18n from "i18next";

export const getLocale = () => {
  const language = i18n?.language?.split("-")?.[0];
  return language === "fr" ? fr : enUS;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const locale = getLocale();
  return format(date, "d MMMM yyyy - HH'h'mm", { locale });
};
