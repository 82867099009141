import i18n from "i18next";

const getLocale = () => {
  const language = i18n?.language?.split("-")?.[0];
  return language === "fr" ? "fr" : "en";
};

export default function formatDuration(duration: {
  value: number;
  unit: "minutes" | "hours";
}) {
  const lang = getLocale();

  const formatMapping = {
    fr: { d: "j", h: "h", m: "min" },
    en: { d: "d", h: "h", m: "m" },
  };

  const { d, h, m } = formatMapping[lang];

  if (duration.unit === "hours") {
    return `${duration.value}${h}`;
  } else {
    const totalMinutes = duration.value;
    const totalHours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);
    const hours = totalHours % 24;
    const days = Math.floor(totalHours / 24);

    const paddedMinute = minutes < 10 ? `0${minutes}` : minutes;

    if (days > 0) {
      return `${days > 0 ? `${days}${d} ` : ""}${
        hours > 0 ? `${hours}${h} ` : ""
      }${minutes > 0 ? `${paddedMinute}${m}` : ""}`.trim();
    } else if (hours > 0) {
      return `${hours}${minutes > 0 ? `${h}${paddedMinute}` : h}`;
    } else {
      return `${minutes} ${m}`;
    }
  }
}
