import { useTranslation } from "react-i18next";
import styles from "./RebookTaxiButton.module.scss";
import { ApiAction } from "src/models/ApiAction";
import { useState } from "react";
import ModalRebookTaxi from "./ModalRebookTaxi/ModalRebookTaxi";

export default function RebookTaxiButton({
  rescheduleTaxiLimits,
  rescheduleTaxiAction,
  currentScheduledAt,
}: {
  rescheduleTaxiLimits?: {
    start: string;
    end: string;
  };
  rescheduleTaxiAction: ApiAction;
  currentScheduledAt: string;
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
          return false;
        }}
        className={styles.delete}
      >
        {t("rebookTaxi")}
      </a>
      {isModalOpen && (
        <ModalRebookTaxi
          onRequestClose={() => setIsModalOpen(false)}
          rescheduleTaxiLimits={rescheduleTaxiLimits}
          rescheduleTaxiAction={rescheduleTaxiAction}
          currentScheduledAt={currentScheduledAt}
          onConfirm={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
}
